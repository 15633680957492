import useModalAlerts from 'components/Alerts/useModalAlerts';
import createApolloClient from 'helpers/graphql/createApolloClient';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import {
  BannerAlertForHeaderDisplayFragment,
  BannerAlertsForHeaderDisplayDocument,
  BannerAlertsForHeaderDisplayQuery,
} from 'types/graphql';

import style from './BannerAlert.module.css';

export default (): JSX.Element => {
  const [bannerAlerts, setBannerAlerts] =
    useState<BannerAlertForHeaderDisplayFragment[]>();

  const [modalAlert, setModalAlert] = useState<React.ReactNode>();
  const { apolloError } = useModalAlerts(setModalAlert);

  useEffect(() => {
    const { client } = createApolloClient();

    client
      .query<BannerAlertsForHeaderDisplayQuery>({
        query: BannerAlertsForHeaderDisplayDocument,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        setBannerAlerts(result.data.bannerAlerts);
      })
      .catch((error) => {
        apolloError({
          error,
        });
      });
  }, []);

  if (modalAlert) {
    return <>{modalAlert}</>;
  }

  if (!(bannerAlerts && bannerAlerts.length)) {
    return <></>;
  }

  return (
    <div>
      {bannerAlerts.map((bannerAlert, idx) => {
        return (
          <Alert
            key={idx}
            variant={bannerAlert.variant}
            className={style.alert}
          >
            {bannerAlert.message}
          </Alert>
        );
      })}
    </div>
  );
};
