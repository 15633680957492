import auth from './AuthClient';

export default () => {
  return {
    isAuthenticated: auth.isAuthenticated(),
    profile: auth.getProfile(),
    idToken: auth.getIdToken(),
    logout: auth.signOut,
  };
};
