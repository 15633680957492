"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleEnum = void 0;
var RoleEnum;
(function (RoleEnum) {
    RoleEnum[RoleEnum["TRGGeneral"] = 100] = "TRGGeneral";
    RoleEnum[RoleEnum["TRGAdmin"] = 101] = "TRGAdmin";
    RoleEnum[RoleEnum["DCAInspectorLicensing"] = 102] = "DCAInspectorLicensing";
    RoleEnum[RoleEnum["DCAReviewerLicensing"] = 103] = "DCAReviewerLicensing";
    RoleEnum[RoleEnum["DCAInvoice"] = 104] = "DCAInvoice";
    RoleEnum[RoleEnum["Inspector"] = 105] = "Inspector";
    RoleEnum[RoleEnum["OperatorRegularUser"] = 106] = "OperatorRegularUser";
    RoleEnum[RoleEnum["OperatorAdmin"] = 107] = "OperatorAdmin";
    RoleEnum[RoleEnum["MRORegularUser"] = 108] = "MRORegularUser";
    RoleEnum[RoleEnum["MROAdmin"] = 109] = "MROAdmin";
})(RoleEnum = exports.RoleEnum || (exports.RoleEnum = {}));
exports.default = RoleEnum;
