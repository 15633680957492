import React, { useContext, useEffect, useState } from 'react';
import AuthClient from 'auth/AuthClient';
import { Redirect } from 'react-router';
import { AuthContext } from 'auth/AuthContext';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { useLocation } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
}

const AuthorizedLayout = (props: IProps) => {
  const { children } = props;
  const location = useLocation();
  const { authInitialized, setAuthInitialized } = useContext(AuthContext);
  const [alertModal, setAlertModal] = useState<any>();
  const { error } = useModalAlerts(setAlertModal);

  useEffect(() => {
    AuthClient.initialize()
      .catch((err) => {
        error({ message: err.message, preventClose: true });
      })
      .finally(() => {
        setAuthInitialized(true);
      });
  }, []);

  if (!!alertModal) {
    return <>{alertModal}</>;
  }

  if (!authInitialized) {
    return <span>Initializing auth</span>;
  }

  if (!AuthClient.isAuthenticated()) {
    return (
      <Redirect
        to={{
          // redirect to the login page
          pathname: '/login',
          // set the page to go to after successful login
          state: { next: location.pathname },
        }}
      />
    );
  }

  return <>{children}</>;
};

export default AuthorizedLayout;
