import React, { PropsWithChildren } from 'react';
import { isViewingDcaSite } from '../../../helpers/site-helper';

export enum SiteEnum {
  ARG,
  DCA,
}

interface SiteProps extends PropsWithChildren<unknown> {
  site: SiteEnum;
}

export const SiteContent = (props: SiteProps) => {
  const isDcaSite = isViewingDcaSite();
  if (props.site === SiteEnum.ARG && !isDcaSite) {
    return <>{props.children}</>;
  } else if (props.site === SiteEnum.DCA && isDcaSite) {
    return <>{props.children}</>;
  }

  return <></>;
};
