export const isUnauthorizedErrorResponse = (error: any): boolean => {
  return isStatusCodeErrorResponse(error, 401);
};

export const isForbiddenErrorResponse = (error: any): boolean => {
  return isStatusCodeErrorResponse(error, 403);
};

export const isStatusCodeErrorResponse = (
  errorResponse: any,
  statusCode: number
): boolean => {
  if (
    !!errorResponse.graphQLErrors?.find(
      (error: any) => error.extensions?.exception?.status === statusCode
    )
  ) {
    return true;
  }

  return false;
};
