import React from 'react';
import BannerAlert from 'view-layouts/components/BannerAlert';
import Header from 'view-layouts/components/Header';

interface IProps {
  children: React.ReactNode;
}

export default (props: IProps) => {
  const { children } = props;

  return (
    <>
      <BannerAlert />
      <Header />

      {children}
    </>
  );
};
