"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adjustDateForIsoPostExact = exports.isoDateToLocalDateExact = exports.areDatesEqual = exports.zeroTime = exports.standardFormatDateTime = exports.standardFormatDate = exports.getNowDate = exports.isoDateTimeStringToLocalDateObject = exports.formatDate = exports.dcaNow = exports.now = void 0;
var date_fns_1 = require("date-fns");
var date_fns_tz_1 = require("date-fns-tz");
var now = function () { return new Date(); };
exports.now = now;
var dcaNow = function () { var _a, _b; return date_fns_tz_1.utcToZonedTime(exports.now(), (_b = (_a = process.env.DCA_TIME_ZONE) !== null && _a !== void 0 ? _a : process.env.REACT_APP_DCA_TIME_ZONE) !== null && _b !== void 0 ? _b : 'America/Aruba'); };
exports.dcaNow = dcaNow;
var formatDate = function (date, formatString) {
    if (formatString === void 0) { formatString = 'MM-dd-yyyy'; }
    if (!date) {
        return '';
    }
    var dateToFormat = typeof date === 'string' ? new Date(date) : date;
    return date_fns_1.format(dateToFormat, formatString);
};
exports.formatDate = formatDate;
var isoDateTimeStringToLocalDateObject = function (isoString) {
    var localDate = date_fns_1.parseISO(isoString);
    return localDate;
};
exports.isoDateTimeStringToLocalDateObject = isoDateTimeStringToLocalDateObject;
var getNowDate = function () {
    var result = exports.now();
    exports.zeroTime(result);
    return result;
};
exports.getNowDate = getNowDate;
var standardFormatDate = function (date) {
    return !!date ? exports.formatDate(date, 'MMM d, yyyy') : '';
};
exports.standardFormatDate = standardFormatDate;
var standardFormatDateTime = function (date) {
    if (!date) {
        return '';
    }
    if (typeof date === 'string')
        date = new Date(date);
    return date ? exports.formatDate(date, 'MM-dd-yyyy, h:mm aa') : '';
};
exports.standardFormatDateTime = standardFormatDateTime;
var zeroTime = function (date) {
    if (!!!date) {
        return date;
    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
};
exports.zeroTime = zeroTime;
var areDatesEqual = function (date1, date2) {
    return Number(date1) === Number(date2);
};
exports.areDatesEqual = areDatesEqual;
// ISO date strings often don't have timezone offset information
// (ex 2019-12-01T00:00:00.000Z).  Once it is used to create a Date object,
// then the date object will be in
// local time which in the case of PST will be 2019-11-30 16:00:00 (i.e. the
// previous day).
// This function will convert an ISO date string in to a Date object where the
// date and time will match what was in the ISO string exactly. So an ISO
// date string of 2019-12-01T00:00:00.000Z will result in a Date object like
// 2019-12-01 PST.
var isoDateToLocalDateExact = function (isoString) {
    if (!isoString) {
        return null;
    }
    var result = new Date(isoString);
    result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
    return result;
};
exports.isoDateToLocalDateExact = isoDateToLocalDateExact;
/**
 * This method will adjust the time to subtract the minutes based on the local environment's offset.
 * It is to be used on a date object so that the date POSTed will be the exact same as how it appears
 * in the local environment once it is converted to ISO format.
 *
 * For example, if a user is in Eastern Time Zone (UTC-5), and simply tries to post a javascript Date
 * object which is set to Dec 1st, 2021 at midnight, then the ISO string that gets posted will actually
 * be like 2021-12-01T05:00:00Z (the offset is added to the time automatically).  Likewise, if a person
 * in the Line Islands (UTC+14) posts a javascript Date object or Dec 1st, 2021 at midnight, then the ISO
 * string posted would be 2021-11-30T10:00:00Z as 14 hours will automatically be removed from the time.
 *
 * Using this method, a user can pass the date that they want to POST, and this method will adjust the date
 * so that if they're trying to POST Dec 1st, 2021 at midnight, then the result will be
 * 2021-12-01T00:00:00Z when converted to an ISO string for POSTing.
 *
 * Example usage:
 * <code>
 *  var localDate = new Date(2021, 11, 1);
 *  var dateToPost = adjustDateForIsoPostExact(localDate);
 *  ...
 * </code>
 * @param date The date object to be adjusted.
 * @returns A new date object with the adjusted time, or null if a null date was provided.
 */
var adjustDateForIsoPostExact = function (date) {
    if (!date) {
        return null;
    }
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
};
exports.adjustDateForIsoPostExact = adjustDateForIsoPostExact;
