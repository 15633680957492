import React from 'react';

interface IProps {
  children: React.ReactNode;
}

const UnauthorizedLayout = (props: IProps) => {
  const { children } = props;

  return <>{children}</>;
};

export default UnauthorizedLayout;
