import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isDca, isTrg, RoleEnum } from 'arms-security';
import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoggedOutContent from '../LoggedOutContent';
import LogoutLink from '../LogoutLink';
import SecuredContent from '../SecuredContent';

const CustomToggle = React.forwardRef(
  (
    { children, onClick }: { children?: React.ReactNode; onClick?: any },
    ref: any
  ) => (
    <Button
      ref={ref}
      variant="link"
      className="menu-toggle"
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  )
);

export const MenuDropdown = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [expandListManagement, setExpandListManagement] =
    useState<boolean>(false);

  const handleToggle = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const close = () => {
    handleToggle(false);
  };

  return (
    <Dropdown show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle as={CustomToggle} id="main-dropdown-menu">
        <FontAwesomeIcon icon={faBars} /> Menu
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0">
        <div className={`menu`} aria-labelledby="aria-labelledby">
          <div className="menu-logo"></div>

          <LoggedOutContent>
            <ul className="list-unstyled">
              <li>
                <Link to="/login" onClick={close}>
                  Login <span className="arrow" />
                </Link>
              </li>
            </ul>
          </LoggedOutContent>

          <SecuredContent>
            <ul className="list-unstyled">
              <li>
                <Link to="/dashboard" onClick={close}>
                  Dashboard <span className="arrow" />
                </Link>
              </li>
            </ul>

            <SecuredContent anyOfGeneralRoles={[isTrg, isDca]}>
              <h1 className="list-heading">Lists</h1>
              <ul className={`list-unstyled titled`}>
                <SecuredContent generalRole={isTrg}>
                  <li>
                    <Link to="/aircrafts" onClick={close}>
                      Aircraft <span className="arrow" />
                    </Link>
                  </li>
                </SecuredContent>
                <SecuredContent generalRole={isTrg}>
                  <li>
                    <Link to="/crewmembers" onClick={close}>
                      Crew <span className="arrow" />
                    </Link>
                  </li>
                </SecuredContent>
                <SecuredContent generalRole={isTrg}>
                  <li>
                    <Link to="/inspectors" onClick={close}>
                      Inspectors <span className="arrow" />
                    </Link>
                  </li>
                </SecuredContent>
                <SecuredContent generalRole={isTrg}>
                  <li>
                    <Link to="/mechanics" onClick={close}>
                      Mechanics <span className="arrow" />
                    </Link>
                  </li>
                </SecuredContent>
                {process.env.REACT_APP_HIDE_MROS_FEATURE !== 'true' && (
                  <SecuredContent generalRole={isTrg}>
                    <li>
                      MROs <span className="arrow" />
                    </li>
                  </SecuredContent>
                )}
                <SecuredContent anyOfGeneralRoles={[isTrg, isDca]}>
                  <li>
                    <Link to="/operators" onClick={close}>
                      Operators <span className="arrow" />
                    </Link>
                  </li>
                </SecuredContent>
                <SecuredContent generalRole={isTrg}>
                  <li>
                    <Link to="/owners" onClick={close}>
                      Owners <span className="arrow" />
                    </Link>
                  </li>
                </SecuredContent>
                <SecuredContent generalRole={isTrg}>
                  <li>
                    <Link to="/registeredholders" onClick={close}>
                      Registered Holders <span className="arrow" />
                    </Link>
                  </li>
                </SecuredContent>
              </ul>
            </SecuredContent>

            <SecuredContent generalRole={isTrg}>
              <h1 className="list-heading">Projects</h1>
              <ul className={`list-unstyled titled`}>
                <li>
                  <Link to="/aircraftprojects" onClick={close}>
                    Aircraft <span className="arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/crewprojects" onClick={close}>
                    Crew <span className="arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/mechanicprojects" onClick={close}>
                    Mechanics <span className="arrow" />
                  </Link>
                </li>
                {process.env.REACT_APP_HIDE_MROS_FEATURE !== 'true' && (
                  <li>
                    MROs <span className="arrow" />
                  </li>
                )}
              </ul>
            </SecuredContent>

            <SecuredContent anyOfGeneralRoles={[isTrg, isDca]}>
              <ul className="list-unstyled">
                <li>
                  <Link to="/invoices" onClick={close}>
                    Invoices <span className="arrow" />
                  </Link>
                </li>
                <SecuredContent role={RoleEnum.TRGAdmin}>
                  <li>
                    Reports <span className="arrow" />
                  </li>
                </SecuredContent>
              </ul>
            </SecuredContent>

            <SecuredContent role={RoleEnum.TRGAdmin}>
              <h1 className="list-heading">Administration</h1>
              <ul className={`list-unstyled titled`}>
                <li>
                  <Link to="/users" onClick={close}>
                    Users <span className="arrow" />
                  </Link>
                </li>
                <li
                  className="pointer"
                  onClick={() => setExpandListManagement(!expandListManagement)}
                >
                  List Management
                  {expandListManagement && (
                    <ul className={`list-unstyled titled`}>
                      <li>
                        <Link
                          to="/listmanagement/aircraftmodel"
                          onClick={close}
                        >
                          Aircraft Models <span className="arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/listmanagement/aircraftmanufacturer"
                          onClick={close}
                        >
                          Aircraft Manufacturers <span className="arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/listmanagement/aircraftenginemanufacturer"
                          onClick={close}
                        >
                          Aircraft Engine Manufacturers{' '}
                          <span className="arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/listmanagement/aircraftpropellermanufacturer"
                          onClick={close}
                        >
                          Aircraft Propeller Manufacturers
                          <span className="arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/listmanagement/crewrating" onClick={close}>
                          Crew Ratings
                          <span className="arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/listmanagement/mechanicrating"
                          onClick={close}
                        >
                          Mechanic Ratings
                          <span className="arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/listmanagement/employmentcompany"
                          onClick={close}
                        >
                          Employing Companies
                          <span className="arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/listmanagement/genericlvcompany"
                          onClick={close}
                        >
                          Generic LV Companies
                          <span className="arrow" />
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </SecuredContent>

            <ul className="list-unstyled">
              <li>
                <LogoutLink />
              </li>
            </ul>
          </SecuredContent>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuDropdown;
