import { ServerError } from '@apollo/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faQuestionCircle,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isForbiddenErrorResponse,
  isUnauthorizedErrorResponse,
} from 'helpers/graphql/errorResponseHelper';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

interface IModalProps {
  title: string;
  icon?: IconProp;
  label?: string | null;
  body: JSX.Element | string;
  variant?: 'danger' | 'warning' | 'success' | 'primary';
  size?: 'sm' | 'lg' | 'xl';
  scrollable?: boolean;
  verticallyCentered?: boolean;
  preventClose?: boolean;
  okButtonText?: string;
  onOkClick?: () => void;
  okButtonVariant?: ButtonVariant;
  cancelButtonText?: string;
  onCancelClick?: () => void;
  cancelButtonVariant?: ButtonVariant;
  onCloseClick?: () => void;
}

export interface IAlertOptions {
  title?: string | null;
  label?: string | null;
  preventClose?: boolean;
  message?: JSX.Element | string | null;
  variant?: 'danger' | 'warning' | 'success' | 'primary';
  size?: 'sm' | 'lg' | 'xl';
  okButtonText?: string;
  onConfirm?: () => void | Promise<void> | null;
}

export interface IErrorsAlertOptions extends IAlertOptions {
  errors: string[];
}

export interface ISuccessAlertOptions extends IAlertOptions {
  timeout?: number | null;
}

export interface ICancelAlertOptions extends IAlertOptions {
  timeout?: number | null;
}

export interface IApolloErrorOptions extends IAlertOptions {
  error: any | unknown;
}

export interface IConfirmOptions extends IAlertOptions {
  onCancel?: () => void | Promise<void> | null;
}

export interface IWarningConfirmationOptions extends IConfirmOptions {
  onCancel?: () => void | Promise<void> | null;
  warnings?: string[];
}

const renderModal = (props: IModalProps) => {
  const className = props.variant
    ? `modal-alert modal-${props.variant}`
    : undefined;

  const canClose = !props.preventClose && !!props.onCloseClick;

  return (
    <Modal
      show={true}
      backdrop={'static'}
      centered={props.verticallyCentered}
      keyboard={false}
      scrollable={props.scrollable}
      className={className}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex">
            {props.icon && (
              <div className="modal-icon-wrapper">
                <FontAwesomeIcon icon={props.icon} />
              </div>
            )}
            <div className="modal-title-text text-uppercase">{props.title}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.label && <label>{props.label}</label>}
        {typeof props.body === 'string' && <p>{props.body}</p>}
        {typeof props.body !== 'string' && <div>{props.body}</div>}
      </Modal.Body>
      <Modal.Footer>
        {!!props.onCancelClick && (
          <Button
            variant={props.cancelButtonVariant ?? 'secondary'}
            size="sm"
            onClick={props.onCancelClick}
          >
            Cancel
          </Button>
        )}
        {!!props.onOkClick && (
          <Button
            variant={props.okButtonVariant ?? props.variant ?? 'primary'}
            size="sm"
            onClick={props.onOkClick}
          >
            {props.okButtonText ?? 'OK'}
          </Button>
        )}

        {canClose && (
          <Button
            variant={'link'}
            size="sm"
            onClick={props.onCloseClick}
            className="d-flex align-items-center"
          >
            <span>Close window</span> <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default (
  setAlertModal: (modal: JSX.Element | Component | null) => void
) => {
  const clickAndClose =
    (onOkClick?: () => void | Promise<void> | null) => async () => {
      // Prevent the function from being called more than once in case where the
      // user clicks the "ok" button at the same time the timeout goes to execute it
      let executed = false;
      if (executed) {
        return;
      }
      executed = true;

      setAlertModal(null);

      if (onOkClick) {
        await onOkClick();
      }
    };

  const error = (props: IAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label ?? 'An error has been encountered',
        icon: faExclamationTriangle,
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'danger',
        preventClose: props.preventClose,
      })
    );
  };

  const errors = (props: IErrorsAlertOptions) => {
    const body = (
      <div>
        {props.message && <p>{props.message}</p>}
        <ul>
          {props.errors.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    );

    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label ?? 'The following errors have been encountered',
        icon: faExclamationTriangle,
        body,
        preventClose: props.preventClose,
        onCloseClick: clickAndClose(),
        variant: 'danger',
      })
    );
  };

  const apolloError = (props: IApolloErrorOptions) => {
    const errorResponse = props.error as any;
    const { graphQLErrors, networkError } = errorResponse;

    if (isUnauthorizedErrorResponse(errorResponse)) {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Unauthorized',
          label: props.label,
          body:
            props.message ?? graphQLErrors?.length
              ? graphQLErrors![0].message // eslint-disable-line @typescript-eslint/no-non-null-assertion
              : 'You must log in to access this resource',
          preventClose: props.preventClose,
          onOkClick: clickAndClose(),
          variant: 'danger',
        })
      );

      return;
    } else if (isForbiddenErrorResponse(errorResponse)) {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Forbidden',
          label: props.label,
          body:
            props.message ?? props.message ?? graphQLErrors?.length
              ? graphQLErrors![0].message // eslint-disable-line @typescript-eslint/no-non-null-assertion
              : 'You do not have permission to access this resource',
          preventClose: props.preventClose,
          onOkClick: clickAndClose(),
          variant: 'danger',
        })
      );

      return;
    }

    // The networkError can be one of 3 different types.
    // A ServerError is encountered for example if a mutation is
    // sent to the server but maybe an invalid input field is specified
    // or a required field was not provided or if there is no subfields
    // specified to return in the result.
    //const error = networkError as Error;
    const serverError = networkError as ServerError;

    const authError: string | null = null;
    const validationErrors: string[] = [];
    if (graphQLErrors) {
      validationErrors.push(
        ...graphQLErrors
          .flatMap((error: any) => {
            const message =
              error.extensions?.response?.message ?? error.message;
            if (Array.isArray(message)) {
              return error.extensions?.response?.message?.filter(
                (message: string) => !!message
              );
            } else {
              return message;
            }
          })
          .filter((m: any) => !!m)
      );
    }

    if (validationErrors.length) {
      errors({
        errors: validationErrors,
        label: props.label,
        preventClose: props.preventClose,
      });
      return;
    }

    if ((props.error as any).stack) {
      console.error((props.error as any).stack);
    }

    const body = (
      <div>
        {props.message && <p>{props.message}</p>}
        {(props.error as any).message && <p>{(props.error as any).message}</p>}
        {authError && <p>{authError}</p>}
        {!!(validationErrors && validationErrors.length) && (
          <>
            <label>Validation Errors</label>
            <ul>
              {validationErrors.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </>
        )}
        {!!(graphQLErrors && graphQLErrors.length) && (
          <ul style={{ display: 'none' }}>
            {graphQLErrors.map((graphQLError: any, index: number) => (
              <li key={index}>{graphQLError.message}</li>
            ))}
          </ul>
        )}
        {!!serverError?.result?.errors && (
          <>
            <label>Server Errors</label>
            <ul>
              {serverError.result.errors.map(
                (error: { message: string }, index: number) => (
                  <li key={index}>{error.message}</li>
                )
              )}
            </ul>
          </>
        )}

        {networkError?.statusCode && <p>status: {networkError?.statusCode}</p>}
      </div>
    );

    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label,
        body,
        icon: faExclamationTriangle,
        preventClose: props.preventClose,
        onOkClick: clickAndClose(),
        variant: 'danger',
      })
    );
  };

  const warning = (props: IAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Warning',
        icon: faExclamationCircle,
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'warning',
      })
    );
  };

  const success = (props: ISuccessAlertOptions) => {
    const handleClose = clickAndClose(props.onConfirm);

    setAlertModal(
      renderModal({
        title: props.title ?? 'Success',
        icon: faCheckCircle,
        label: 'The operation was successful',
        body: props.message ?? '',
        onCloseClick: handleClose,
        variant: 'success',
      })
    );

    setTimeout(handleClose, props.timeout ?? 1500);
  };

  const cancel = (props?: ICancelAlertOptions) => {
    const handleClose = clickAndClose(props?.onConfirm);

    setAlertModal(
      renderModal({
        title: props?.title ?? 'Cancelled',
        icon: faCheckCircle,
        label: 'Cancelled',
        body: props?.message ?? 'The operation was cancelled as requested',
        onCloseClick: handleClose,
        variant: 'warning',
      })
    );
  };

  const confirm = (props: IConfirmOptions) => {
    const promise = new Promise<void>((resolve, reject) => {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Confirmation',
          icon: faQuestionCircle,
          body: props.message ?? '',
          okButtonText: props.okButtonText,
          size: props.size,
          onOkClick: () => {
            clickAndClose(props.onConfirm)();
            resolve();
          },
          onCancelClick: () => {
            clickAndClose(props.onCancel)();
            reject('cancelled');
          },
          variant: props.variant ?? 'warning',
        })
      );
    });

    return promise;
  };

  const confirmWarnings = (
    props: IWarningConfirmationOptions
  ): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
      const okButtonText = props.okButtonText ?? 'Continue';

      let body = props.message;
      if (!!props.warnings?.length) {
        body = (
          <div>
            <p>
              The following warning
              {props.warnings.length > 1 ? 's' : ''}{' '}
              {props.warnings.length === 1 ? 'was' : 'were'} encountered.
            </p>
            <ul>
              {props.warnings.map((warning: string, index: number) => (
                <li key={index} className="mt-3">
                  {warning}
                </li>
              ))}
            </ul>
            <p>
              Click <i>{props.okButtonText ?? 'Continue'}</i> to ignore or{' '}
              <i>Cancel</i> to go back.
            </p>
          </div>
        );
      }

      setAlertModal(
        renderModal({
          title: props.title ?? 'Warning',
          icon: faExclamationCircle,
          body: body ?? '',
          okButtonText: okButtonText,
          size: props.size ?? 'lg',
          onOkClick: () => {
            clickAndClose(props.onConfirm)();
            resolve();
          },
          onCancelClick: () => {
            clickAndClose(props.onCancel)();
            reject('cancelled');
          },
          variant: props.variant ?? 'warning',
        })
      );
    });

    return promise;
  };

  return {
    apolloError,
    confirm,
    confirmWarnings,
    error,
    errors,
    success,
    cancel,
    warning,
  };
};
