import React from 'react';
import MenuDropdown from '../MenuDropdown';
import SecuredContent from '../SecuredContent';
import { CurrentUserDisplayName } from 'components/CurrentUserDisplayName';
import Column from 'components/GridLayout/Column';
import Version from 'components/Version';
import EnvironmentBadge from 'components/EnvironmentBadge';
import { SiteContent, SiteEnum } from '../SiteContent';

export default () => {
  return (
    <header className="site-header container-fluid">
      <div className="row">
        <Column width={1}>
          <SecuredContent>
            <MenuDropdown />
          </SecuredContent>
        </Column>
        <SecuredContent>
          <Column>
            ARMS <Version /> | <CurrentUserDisplayName />{' '}
            <EnvironmentBadge className="ml-3" />
          </Column>
        </SecuredContent>
        <Column>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div className={`header-logo`}></div>

            <SiteContent site={SiteEnum.DCA}>
              <span>Department of Civil Aviation of Aruba</span>
            </SiteContent>
          </div>
        </Column>
      </div>
    </header>
  );
};
