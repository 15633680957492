import React from 'react';
import useAuth from 'auth/useAuth';

interface LoggedOutContentProps {
  children: any;
}

export default ({ children }: LoggedOutContentProps) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};
