import React, { CSSProperties } from 'react';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type WidthValue =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 'auto';
type OffsetValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export interface IColumnProps extends Record<string, any> {
  breakpoint?: Breakpoint;
  width?: WidthValue;
  offset?: OffsetValue;
  className?: string;
  gutters?: boolean;
  xs?: WidthValue;
  md?: WidthValue;
  lg?: WidthValue;
  xl?: WidthValue;
  children?: any;
  style?: CSSProperties;
}

export default (props: IColumnProps): JSX.Element => {
  let className = 'col';
  if (props.breakpoint) {
    className = `${className}-${props.breakpoint}`;
  }

  if (props.width) {
    className = `${className}-${props.width}`;
  }

  if (props.offset) {
    className = `${className} offset-${props.offset}`;
  }

  ['xs', 'sm', 'md', 'lg', 'xl'].forEach((breakpoint) => {
    if (!!props[breakpoint]) {
      className = `${className} col-${breakpoint}-${props[breakpoint]}`;
    }
  });

  const gutters = props.gutters ?? true;
  if (!gutters) {
    className = `${className} no-gutters`;
  }

  if (props.className) {
    className = `${className} ${props.className}`;
  }

  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
};
