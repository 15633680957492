import React from 'react';
import { Badge } from 'react-bootstrap';

interface IProps {
  className?: string;
}

export default (props: IProps) => {
  const value = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;
  if (value !== 'production') {
    return (
      <Badge className={props.className} variant="warning">
        {value}
      </Badge>
    );
  }
  return <></>;
};
