/**
 * Based on example at https://auth0.com/blog/react-tutorial-building-and-securing-your-first-app/
 */
import { now } from 'arms-common/lib/utils/date-utils';
import { IAuthProfile } from 'arms-security';
import Axios, { AxiosInstance } from 'axios';
import { isViewingDcaSite } from 'helpers/site-helper';
import jwt_decode from 'jwt-decode';

class AuthClient {
  private profile: IAuthProfile = {} as IAuthProfile;
  private idToken?: string | null = null;
  private expiresAt?: number | null = null;

  private axiosClient: AxiosInstance;

  private timeout?: NodeJS.Timeout;

  private initialized = false;

  constructor() {
    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.initialize = this.initialize.bind(this);

    this.axiosClient = Axios.create({
      // We always want to make sure that we use the current site's /api reverse proxy URL
      // so that cookies get set for the current site.  If we used the API container's URL
      // directly, then the cookie will be associated with that API host.
      baseURL: '/api',
      withCredentials: true, //needed so that axios will pass along cookies with requests
    });

    //this.initialize();
  }

  getProfile = (): IAuthProfile => this.profile;

  getIdToken = () => this.idToken;

  isAuthenticated = (): boolean =>
    !!(this.expiresAt && now().getTime() < this.expiresAt);

  signIn = () => {
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  };

  handleAuthentication = async (username: string, password: string) => {
    return this.axiosClient
      .post(`/auth/login`, { username, password })
      .then(this.handleLoginOrRefreshResponse);
  };

  setSession = (authResult: { idToken: string; idTokenPayload: any }) => {
    this.idToken = authResult.idToken;
    this.profile = {
      ...authResult.idTokenPayload,
      isMaintenanceInspector:
        !!authResult.idTokenPayload.isMaintenanceInspector,
      isMechanicProjectDcaReviewerUser:
        !!authResult.idTokenPayload.isMechanicProjectDcaReviewerUser,
      isMechanicProjectDcaSentForApprovalByUser:
        !!authResult.idTokenPayload.isMechanicProjectDcaSentForApprovalByUser,
      isMechanicProjectDcaApprovedByUser:
        !!authResult.idTokenPayload.isMechanicProjectDcaApprovedByUser,
      isSuperUser: !!authResult.idTokenPayload.isSuperUser,
    };

    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
  };

  signOut = async () => {
    // clear id token, profile, and expiration
    this.idToken = null;
    this.profile = {} as IAuthProfile;
    this.expiresAt = null;

    await this.axiosClient.post(`/auth/logout`).finally(() => {
      this.signIn();
    });
  };

  silentRefresh = async () => {
    return this.axiosClient
      .post(`/auth/refresh`)
      .then(this.handleLoginOrRefreshResponse)
      .catch((reason) => {
        if (reason.response?.data?.statusCode === 401) {
          this.signOut();
        } else {
          throw reason;
        }
      });
  };

  initialize = async () => {
    if (!this.initialized) {
      return this.silentRefresh().then(() => {
        this.initialized = true;
      });
    }
  };

  private handleLoginOrRefreshResponse = (response: {
    data: { idToken: string };
  }) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const { idToken } = response.data;
    this.setSession({
      idToken,
      idTokenPayload: jwt_decode(idToken),
    });

    if (
      window.location.hostname !== 'localhost' &&
      !!this.profile &&
      !this.profile.isSuperUser
    ) {
      if (isViewingDcaSite() && !this.profile.isDca) {
        throw new Error('Not authorized to access DCA site as a non-DCA user');
      } else if (!isViewingDcaSite() && this.profile.isDca) {
        throw new Error('Not authorized to access this site as DCA user');
      }
    }

    if (this.expiresAt) {
      const nextRefreshTime = this.expiresAt - 5000;
      const millisecondsUntilNextRefresh = nextRefreshTime - now().getTime();

      this.timeout = setTimeout(() => {
        this.silentRefresh();
      }, millisecondsUntilNextRefresh);
    }

    return response;
  };

  /**
   * Only to be used for testing purposes.
   * @param profile
   */
  setProfile = (profile: IAuthProfile) => {
    this.profile = profile;
  };

  /**
   * Only to be used for testing purposes.
   * @param profile
   */
  setAuthenticationExpiry = (datetime: Date) => {
    this.expiresAt = datetime.getTime();
  };
}

const authClient = new AuthClient();

export default authClient;
