import '@fortawesome/fontawesome-svg-core/styles.css'; // must be first line
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n'; // import i18n (needs to be bundled ;))
import { SpinnerModal2Provider } from './components/SpinnerModal2/SpinnerModal2';
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

ReactDOM.render(
  <React.StrictMode>
    <SpinnerModal2Provider>
      <App />
    </SpinnerModal2Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
