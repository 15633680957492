import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import auth from 'auth/AuthClient';
import { apiBaseUrl } from 'helpers/site-helper';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { UploadLinkOptions } from 'apollo-upload-client/public/createUploadLink';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      // tslint:disable-next-line
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ` +
          `${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    // tslint:disable-next-line
    console.warn(`[Network error]: ${networkError}`);
  }
});

export const createApolloClient = (options?: {
  uploadLinkOptions?: Pick<UploadLinkOptions, 'headers'>;
  clientOptions?: Partial<ApolloClientOptions<unknown>>;
}) => {
  const idToken = auth.getIdToken();
  let defaultHeaders = {};
  if (idToken) {
    defaultHeaders = {
      ...defaultHeaders,
      Authorization: `Bearer ${idToken}`,
    };
  }

  const uploadLinkOptions: UploadLinkOptions = {
    uri: `${apiBaseUrl()}/graphql`,
    headers: defaultHeaders,
    ...options?.uploadLinkOptions,
  };

  const uploadLink = createUploadLink(uploadLinkOptions);

  const link = ApolloLink.from([errorLink, uploadLink] as ApolloLink[]);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
    /*
    onError: (error) => {
      if (isUnauthorizedErrorResponse(error)) {
        auth.signOut();
      }
    },
    */
    ...options?.clientOptions,
  });

  return { client };
};

export default createApolloClient;
