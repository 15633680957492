import React, { ReactNode, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

interface ISpinnerModal2Options {
  title?: string;
  message: string;
  verticallyCentered?: boolean;
}

export interface ISpinnerModal2Context {
  openSpinnerModal: (options: ISpinnerModal2Options) => void;
  closeSpinnerModal: () => void;
}

const initialContext: ISpinnerModal2Context = {
  openSpinnerModal: (): void => {
    return;
  },
  closeSpinnerModal: (): void => {
    return;
  },
};

// The following is the actual spinner
const SpinnerModal2Context =
  React.createContext<ISpinnerModal2Context>(initialContext);

type Props = {
  children: ReactNode;
};

export const SpinnerModal2Provider = ({ children }: Props): JSX.Element => {
  const [options, setOptions] = React.useState<ISpinnerModal2Options | null>(
    null
  );

  const handleShowSpinnerModal = (options: ISpinnerModal2Options): void => {
    setOptions(options);
  };

  const handleHideSpinnerModal = (): void => {
    setOptions(null);
  };

  return (
    <SpinnerModal2Context.Provider
      value={{
        openSpinnerModal: handleShowSpinnerModal,
        closeSpinnerModal: handleHideSpinnerModal,
      }}
    >
      {options && (
        <Modal
          show={true}
          backdrop={'static'}
          centered={options.verticallyCentered ?? options.verticallyCentered}
          keyboard={false}
          scrollable={false}
          className="modal-alert modal-primary"
        >
          {!!(options?.title ?? options.title) && (
            <Modal.Header>
              <Modal.Title>
                <div className="d-flex">
                  <div className="modal-icon-wrapper">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  </div>
                  <div className="modal-title-text text-uppercase ml-2 mt-1">
                    {options?.title ?? options.title}
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
          )}
          <Modal.Body>
            {!(options?.title ?? options.title) && (
              <div className="text-center">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              </div>
            )}
            <div className="text-center">
              {!!(options?.message ?? options.message) && (
                <span>{options?.message ?? options.message}</span>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {children}
    </SpinnerModal2Context.Provider>
  );
};

// Any other components in the system can manipulate the spinner with the following
export const useSpinnerModal2: () => ISpinnerModal2Context = () =>
  useContext(SpinnerModal2Context);
