import React from 'react';
import useAuth from 'auth/useAuth';
import { Button } from 'react-bootstrap';

export default () => {
  const { logout } = useAuth();

  return (
    <Button variant="link" onClick={logout} className="logout-link">
      Logout
    </Button>
  );
};
