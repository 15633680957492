"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRoleAdmin = exports.isMro = exports.isOperator = exports.isDcaLicensing = exports.isDca = exports.isTrg = exports.RoleEnum = void 0;
var role_enum_1 = require("./role.enum");
Object.defineProperty(exports, "RoleEnum", { enumerable: true, get: function () { return role_enum_1.RoleEnum; } });
var role_helper_1 = require("./role-helper");
Object.defineProperty(exports, "isTrg", { enumerable: true, get: function () { return role_helper_1.isTrg; } });
Object.defineProperty(exports, "isDca", { enumerable: true, get: function () { return role_helper_1.isDca; } });
Object.defineProperty(exports, "isDcaLicensing", { enumerable: true, get: function () { return role_helper_1.isDcaLicensing; } });
Object.defineProperty(exports, "isOperator", { enumerable: true, get: function () { return role_helper_1.isOperator; } });
Object.defineProperty(exports, "isMro", { enumerable: true, get: function () { return role_helper_1.isMro; } });
Object.defineProperty(exports, "isRoleAdmin", { enumerable: true, get: function () { return role_helper_1.isRoleAdmin; } });
