import { isDca, isOperator, isTrg, RoleEnum } from 'arms-security';
import { AnimatePresence, motion } from 'framer-motion';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { UnauthorizedLayout } from 'view-layouts';
import AuthorizedLayout from 'view-layouts/AuthorizedLayout';
import SecuredContent from 'view-layouts/components/SecuredContent';
import MainLayout from 'view-layouts/MainLayout';

const AircraftListPage = lazy(() => import('views/Aircraft/AircraftListPage'));
const AircraftPage = lazy(() => import('views/Aircraft/AircraftPage'));
const AircraftProjectListPage = lazy(
  () => import('views/AircraftProject/AircraftProjectListPage')
);
const AircraftProjectPage = lazy(
  () => import('views/AircraftProject/AircraftProjectPage')
);
const CrewCertificateDownloadView = lazy(
  () => import('views/Certificates/CrewCertificateDownload')
);
const CrewCertificateValidationV1View = lazy(
  () => import('views/Certificates/CrewCertificateValidationV1View')
);
const CrewCertificateValidationV2View = lazy(
  () => import('views/Certificates/CrewCertificateValidationV2View')
);
const CrewMemberListPage = lazy(
  () => import('views/CrewMember/CrewMemberListPage')
);
const CrewMemberPage = lazy(() => import('views/CrewMember/CrewMemberPage'));
const CrewProjectListPage = lazy(
  () => import('views/CrewProject/CrewProjectListPage')
);
const CrewProjectPage = lazy(() => import('views/CrewProject/CrewProjectPage'));
const DashboardView = lazy(() => import('views/Dashboard'));
const ForgotPasswordView = lazy(() => import('./views/ForgotPassword'));
const InspectorListPage = lazy(
  () => import('views/Inspector/InspectorListPage')
);
const InspectorPage = lazy(() => import('views/Inspector/InspectorPage'));
const InvoiceListPage = lazy(() => import('views/Invoice/InvoiceListPage'));
const LoginView = lazy(() => import('./views/Login'));
const MechanicListPage = lazy(() => import('views/Mechanic/MechanicListPage'));
const MechanicPage = lazy(() => import('views/Mechanic/MechanicPage'));
const MechanicProjectListPage = lazy(
  () => import('views/MechanicProject/MechanicProjectListPage')
);
const MechanicProjectPage = lazy(
  () => import('views/MechanicProject/MechanicProjectPage')
);
const MechanicCertificateDownloadView = lazy(
  () => import('views/Certificates/MechanicCertificateDownload')
);
const MechanicCertificateValidationV1View = lazy(
  () => import('views/Certificates/MechanicCertificateValidationV1View')
);
const OperatorListPage = lazy(() => import('views/Operator/OperatorListPage'));
const OperatorPage = lazy(() => import('views/Operator/OperatorPage'));
const OwnerListPage = lazy(() => import('views/Owner/OwnerListPage'));
const OwnerPage = lazy(() => import('views/Owner/OwnerPage'));
const RegisteredHolderListPage = lazy(
  () => import('views/RegisteredHolder/RegisteredHolderListPage')
);
const RegisteredHolderPage = lazy(
  () => import('views/RegisteredHolder/RegisteredHolderPage')
);
const ResetPasswordView = lazy(() => import('./views/ResetPassword'));
const UserListPage = lazy(() => import('views/User/UserListPage'));

const CrewProjectInsFormExecutionView = lazy(
  () => import('./views/InsFormExecution/CrewProjects')
);
const CrewProjectInsFormExecutionDownloadView = lazy(
  () => import('./views/InsFormExecution/CrewProjects/Download')
);

const MechanicProjectInsFormExecutionView = lazy(
  () => import('./views/InsFormExecution/MechanicProjects')
);
const MechanicProjectInsFormExecutionDownloadView = lazy(
  () => import('./views/InsFormExecution/MechanicProjects/Download')
);

const AircraftModelListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/AircraftModelListManagement/AircraftModelListManagementPage'
    )
);
const AircraftManufacturerListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/AircraftManufacturerListManagement/AircraftManufacturerListManagementPage'
    )
);
const AircraftEngineManufacturerListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/AircraftEngineManufacturerListManagement/AircraftEngineManufacturerListManagementPage'
    )
);
const AircraftPropellerManufacturerListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/AircraftPropellerManufacturerListManagement/AircraftPropellerManufacturerListManagementPage'
    )
);
const EmploymentCompanyListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/EmploymentCompanyListManagement/EmploymentCompanyListManagementPage'
    )
);
const GenericLvCompanyListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/GenericLvCompanyListManagement/GenericLvCompanyListManagementPage'
    )
);
const CrewRatingListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/CrewRatingListManagement/CrewRatingListManagementPage'
    )
);
const MechanicRatingListManagementPage = lazy(
  () =>
    import(
      './views/ListManagement/MechanicRatingListManagement/MechanicRatingListManagementPage'
    )
);

const DeveloperForm = lazy(() => import('./views/Developer/Form'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  };

  return (
    <AnimatePresence>
      <Suspense
        fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div className="w-50 mx-auto">...</div>
          </div>
        }
      >
        <MainLayout>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />

            {process.env.NODE_ENV === 'development' && (
              <Route path="/developer/form" component={DeveloperForm} />
            )}

            <Route
              path="/certificates/:id/download"
              component={CrewCertificateDownloadView}
            />

            <Route
              path="/certificates/:id/validate"
              component={CrewCertificateValidationV1View}
            />

            <Route
              path="/certficiate/:id/validate" // the original URL in v1 QR codes was misspelled
              component={CrewCertificateValidationV1View}
            />

            <Route
              path="/certificates/:id/validate-crew-v2"
              component={CrewCertificateValidationV2View}
            />

            <Route
              path="/crew-projects/forms/:key"
              exact={true}
              component={CrewProjectInsFormExecutionView}
            />

            <Route
              path="/crew-projects/forms/:key/download"
              component={CrewProjectInsFormExecutionDownloadView}
            />

            <Route
              path="/mechanic-projects/forms/:key"
              exact={true}
              component={MechanicProjectInsFormExecutionView}
            />

            <Route
              path="/mechanic-projects/forms/:key/download"
              component={MechanicProjectInsFormExecutionDownloadView}
            />

            <Route
              path="/certificates/:id/download-mechanic"
              component={MechanicCertificateDownloadView}
            />

            <Route
              path="/certificates/:id/validate-mechanic-v1"
              component={MechanicCertificateValidationV1View}
            />

            <Route path={['/login', '/forgot-password', '/reset-password']}>
              <UnauthorizedLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Route path="/login" component={LoginView} />
                    <Route
                      path="/forgot-password"
                      component={ForgotPasswordView}
                    />
                    <Route
                      path="/reset-password"
                      component={ResetPasswordView}
                    />
                  </motion.div>
                </Switch>
              </UnauthorizedLayout>
            </Route>

            <Route
              path={[
                '/aircrafts',
                '/aircraft',
                '/aircraftprojects',
                '/aircraftproject',
                '/crewmembers',
                '/crewmember',
                '/crewprojects',
                '/crewproject',
                '/mechanics',
                '/mechanic',
                '/mechanicprojects',
                '/mechanicproject',
                '/dashboard',
                '/inspectors',
                '/inspector',
                '/invoices',
                '/operators',
                '/operator',
                '/owners',
                '/owner',
                '/registeredholders',
                '/registeredholder',
                '/users',
                '/listmanagement/aircraftmodel',
                '/listmanagement/aircraftmanufacturer',
                '/listmanagement/aircraftenginemanufacturer',
                '/listmanagement/aircraftpropellermanufacturer',
                '/listmanagement/employmentcompany',
                '/listmanagement/genericlvcompany',
                '/listmanagement/crewrating',
                '/listmanagement/mechanicrating',
              ]}
            >
              <AuthorizedLayout>
                <Switch>
                  <Route path={['/dashboard']}>
                    <SecuredContent
                      anyOfGeneralRoles={[isTrg, isDca, isOperator]}
                      role={RoleEnum.Inspector}
                    >
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/dashboard/:tab?"
                          component={DashboardView}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/users']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/users" component={UserListPage} />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/listmanagement/aircraftmodel']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/aircraftmodel"
                          component={AircraftModelListManagementPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>
                  <Route path={['/listmanagement/aircraftmanufacturer']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/aircraftmanufacturer"
                          component={AircraftManufacturerListManagementPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>
                  <Route path={['/listmanagement/aircraftenginemanufacturer']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/aircraftenginemanufacturer"
                          component={
                            AircraftEngineManufacturerListManagementPage
                          }
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>
                  <Route
                    path={['/listmanagement/aircraftpropellermanufacturer']}
                  >
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/aircraftpropellermanufacturer"
                          component={
                            AircraftPropellerManufacturerListManagementPage
                          }
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>
                  <Route path={['/listmanagement/crewrating']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/crewrating"
                          component={CrewRatingListManagementPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>
                  <Route path={['/listmanagement/mechanicrating']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/mechanicrating"
                          component={MechanicRatingListManagementPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>
                  <Route path={['/listmanagement/employmentcompany']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/employmentcompany"
                          component={EmploymentCompanyListManagementPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>
                  <Route path={['/listmanagement/genericlvcompany']}>
                    <SecuredContent role={RoleEnum.TRGAdmin}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/listmanagement/genericlvcompany"
                          component={GenericLvCompanyListManagementPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/crewmembers']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/crewmembers"
                          component={CrewMemberListPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/crewmember']}>
                    <SecuredContent anyOfGeneralRoles={[isTrg, isOperator]}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/crewmember/:id/:tab?"
                          component={CrewMemberPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/crewprojects']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/crewprojects"
                          component={CrewProjectListPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/crewproject']}>
                    <SecuredContent
                      anyOfGeneralRoles={[isTrg, isDca, isOperator]}
                    >
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/crewproject/:id/:tab?"
                          component={CrewProjectPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/mechanics']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/mechanics" component={MechanicListPage} />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/mechanic']}>
                    <SecuredContent anyOfGeneralRoles={[isTrg, isOperator]}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/mechanic/:id/:tab?"
                          component={MechanicPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/mechanicprojects']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/mechanicprojects"
                          component={MechanicProjectListPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/mechanicproject']}>
                    <SecuredContent
                      anyOfGeneralRoles={[isTrg, isDca, isOperator]}
                    >
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/mechanicproject/:id/:tab?"
                          component={MechanicProjectPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/operators']}>
                    <SecuredContent anyOfGeneralRoles={[isTrg, isDca]}>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/operators" component={OperatorListPage} />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/operator']}>
                    <SecuredContent
                      anyOfGeneralRoles={[isTrg, isDca, isOperator]}
                    >
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/operator/:id/:tab?/:subTab?"
                          component={OperatorPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/owners']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/owners" component={OwnerListPage} />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/owner']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/owner/:id/:tab?" component={OwnerPage} />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/registeredholders']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/registeredholders"
                          component={RegisteredHolderListPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/registeredholder']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/registeredholder/:id/:tab?"
                          component={RegisteredHolderPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/aircrafts']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/aircrafts" component={AircraftListPage} />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/aircraft']}>
                    <SecuredContent anyOfGeneralRoles={[isTrg, isOperator]}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/aircraft/:id/:tab?/:subTab?"
                          component={AircraftPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/aircraftprojects']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/aircraftprojects"
                          component={AircraftProjectListPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/aircraftproject']}>
                    <SecuredContent
                      anyOfGeneralRoles={[isTrg, isDca, isOperator]}
                      anyOfRoles={[RoleEnum.Inspector]}
                    >
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/aircraftproject/:id/:tab?/:subTab?"
                          component={AircraftProjectPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/inspectors']}>
                    <SecuredContent generalRole={isTrg}>
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/inspectors"
                          component={InspectorListPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/inspector']}>
                    <SecuredContent
                      generalRole={isTrg}
                      role={RoleEnum.Inspector}
                    >
                      <Switch location={location} key={location.pathname}>
                        <Route
                          path="/inspector/:id/:tab?"
                          component={InspectorPage}
                        />
                      </Switch>
                    </SecuredContent>
                  </Route>

                  <Route path={['/invoices']}>
                    <SecuredContent anyOfGeneralRoles={[isTrg, isDca]}>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/invoices" component={InvoiceListPage} />
                      </Switch>
                    </SecuredContent>
                  </Route>
                </Switch>
              </AuthorizedLayout>
            </Route>
          </Switch>
        </MainLayout>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
