"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRoleAdmin = exports.isInspector = exports.isMro = exports.isOperator = exports.isDca = exports.isDcaInvoicer = exports.isDcaLicensing = exports.isTrg = void 0;
var role_enum_1 = require("./role.enum");
var isTrg = function (role) {
    return !!role && [role_enum_1.RoleEnum.TRGGeneral, role_enum_1.RoleEnum.TRGAdmin].includes(role);
};
exports.isTrg = isTrg;
var isDcaLicensing = function (role) {
    return !!role && [role_enum_1.RoleEnum.DCAInspectorLicensing, role_enum_1.RoleEnum.DCAReviewerLicensing].includes(role);
};
exports.isDcaLicensing = isDcaLicensing;
var isDcaInvoicer = function (role) {
    return !!role && [role_enum_1.RoleEnum.DCAInvoice].includes(role);
};
exports.isDcaInvoicer = isDcaInvoicer;
var isDca = function (role) {
    return !!role && (exports.isDcaLicensing(role) || exports.isDcaInvoicer(role));
};
exports.isDca = isDca;
var isOperator = function (role) {
    return !!role && [role_enum_1.RoleEnum.OperatorRegularUser, role_enum_1.RoleEnum.OperatorAdmin].includes(role);
};
exports.isOperator = isOperator;
var isMro = function (role) {
    return !!role && [role_enum_1.RoleEnum.MRORegularUser, role_enum_1.RoleEnum.MROAdmin].includes(role);
};
exports.isMro = isMro;
var isInspector = function (role) {
    return role === role_enum_1.RoleEnum.Inspector;
};
exports.isInspector = isInspector;
var isRoleAdmin = function (role) {
    return !!role && [role_enum_1.RoleEnum.TRGAdmin, role_enum_1.RoleEnum.OperatorAdmin, role_enum_1.RoleEnum.MROAdmin].includes(role);
};
exports.isRoleAdmin = isRoleAdmin;
