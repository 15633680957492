import { isInGeneralRoleFn, RoleEnum } from 'arms-security';
import useAuth from 'auth/useAuth';
import React, { PropsWithChildren } from 'react';

interface SecuredContentProps extends PropsWithChildren<unknown> {
  role?: RoleEnum;
  anyOfRoles?: RoleEnum[];
  generalRole?: isInGeneralRoleFn;
  anyOfGeneralRoles?: isInGeneralRoleFn[];
  unauthorizedChildren?: React.ReactNode;
  disallowSuperUser?: boolean;
  isPermitted?: () => boolean;
}

const SecuredContent = ({
  role,
  anyOfRoles,
  generalRole,
  anyOfGeneralRoles,
  children,
  unauthorizedChildren,
  disallowSuperUser,
  isPermitted,
}: SecuredContentProps): JSX.Element => {
  const { isAuthenticated, profile } = useAuth();

  // If the user is not authenticated at all then return the unauthenticated content.
  if (!isAuthenticated) {
    return <>{unauthorizedChildren}</>;
  }

  // If there are no roles defined to restrict access to this content, then just return the content here.
  if (
    !(
      !!role ||
      anyOfRoles?.length ||
      !!generalRole ||
      anyOfGeneralRoles?.length ||
      !!isPermitted
    )
  ) {
    return <>{children}</>;
  }

  if (profile.isSuperUser && !disallowSuperUser) {
    return <>{children}</>;
  }

  // check from most specific to least specific

  if (!!role && profile.role === role) {
    return <>{children}</>;
  }

  if (
    !!anyOfRoles?.length &&
    !!profile.role &&
    anyOfRoles.includes(profile.role)
  ) {
    return <>{children}</>;
  }

  if (!!generalRole && generalRole(profile.role)) {
    return <>{children}</>;
  }

  if (
    !!anyOfGeneralRoles?.length &&
    anyOfGeneralRoles.find((anyOfGeneralRole) => anyOfGeneralRole(profile.role))
  ) {
    return <>{children}</>;
  }

  if (!!isPermitted && isPermitted()) {
    return <>{children}</>;
  }

  return <>{unauthorizedChildren}</>;
};

export default SecuredContent;
